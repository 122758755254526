<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="700px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <div class="my-free">
            <div class="my-free-check pr10">
              <el-checkbox size="middle" v-model="checked" :disabled="true"></el-checkbox>
            </div>
            <div class="pr10">
              <img class="my-free-img" :src="curItem.dictItemDescribe || ''"/>
            </div>
            <div class="my-free-select">
              <el-select :disabled="editType != 'add'" v-model="curItem.dictCode" class="full-width"  @change="selecChange2">
                <el-option
                  v-for="(item, index) in selectObj.pro"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictCode">
                </el-option>
              </el-select>
              <div class="my-free-cont pt10">
                <p>单价：<span class="fisp-price">{{curItem.price || 0}}</span></p>
                <p>数量：
                  <el-input-number :disabled=" !curItem.price || editType != 'add'" v-model="curItem.buyNum" :step="1" :min="1" @change="handleChange" size="small"></el-input-number>
                </p>
              </div>
              <div class="pb10">金额：<span class="fisp-price">{{(curItem.price || 0) * (curItem.buyNum || 1)}}</span></div>
            </div>
          </div>

          <div class="my-free" v-for="(item, index) in list2" :key="index">
            <div class="my-free-check pr10">
              <el-checkbox size="middle" :disabled="editType != 'add'" @change="handleChange" v-model="item.checked"></el-checkbox>
            </div>
            <div class="pr10">
              <img class="my-free-img" :src="item.dictItemDescribe || ''"/>
            </div>
            <div class="my-free-select">
              <div>{{item.dictName}}</div>
              <div class="my-free-cont pt10">
                <p>单价：<span class="fisp-price">{{ item.price}}</span></p>
                <p>数量：
                  <el-input-number :disabled="editType != 'add'" v-model="item.buyNum" :step="1" :min="1" @change="handleChange" size="small">
                  </el-input-number></p>
              </div>
              <div class="pb10">金额：<span class="fisp-price">{{ (item.price || 0) * (item.buyNum || 1 ) }}</span></div>
            </div>
          </div>

          <el-row class="free-certificate-shot">
            <el-col :span="24">
              赠送说明：一次购买100本，送5%的量，一次购买500本送10%，一次购买2000本，送20%
            </el-col>
          </el-row>
          <el-row class="free-certificate-shot">
            <el-col :span="24">运费：{{ruleForm.expressFee}}元</el-col>
          </el-row>
          <el-row class="free-certificate-shot">
            <el-col :span="24">实付总价：{{ruleForm.allPrice}}元</el-col>
          </el-row>
          <el-row class="free-certificate-shot">
            <el-col :span="24">赠送数量：{{ruleForm.freeNum}}</el-col>
          </el-row>

          <el-row class="pt20">
            <el-col :span="24">
              <el-form-item label="标签收货人姓名：" prop="consignee">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.consignee }}</p>
                <el-input v-else clearable v-model="ruleForm.consignee" placeholder="请输入标签收货人姓名" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="手机号：" prop="phoneNum">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.phoneNum }}</p>
                <el-input v-else clearable v-model="ruleForm.phoneNum" placeholder="请输入手机号" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="收货地址：" prop="address">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.address }}</p>
                <el-input v-else v-model="ruleForm.address" placeholder="请输入收货地址" maxlength=100 show-word-limit class="input-with-select-blue">
                  <el-select v-model="ruleForm.historyName" @change="historyChange" slot="append" placeholder="可选择历史地址">
                    <el-option
                      v-for="(item, index) in selectObj.baseNames"
                      :key="index"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-input>
                <el-input v-else clearable v-model="ruleForm.address" placeholder="请输入收货地址" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm('ruleForm')">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pay ref="pay" @getpay="getpay"></pay>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pay from '@/views/common/pay.vue'
export default {
  name: "freeEdit",
  components: {
    pay
  },
  props: {},
  data() {
    return {   
      rules: {
        address: [{ required: true,  trigger: "change", message: '请输入收货地址'}],
        phoneNum: [{ required: true,  trigger: "change", message: '请输入手机号'}],
        phoneNum: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入手机号"))
            }else if(!baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        consignee: [{ required: true,  trigger: "change", message: '请输入标签收货人姓名'}]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        baseNames: [],
        pro: []
      },
      curItem: {},
      free1: 10,
      free2: 20,
      list1:[],
      list2:[],
      checked: true,
      initDiceVo: []
    };
  },
  created() {
    this.getDictData()
	},
  methods: {
    getpay(flag){
      this.$emit("getData")
      this.closeModel()
    },
    historyChange(val){
      this.ruleForm.address = val
      this.ruleForm.historyName = '历史地址'
      this.addressChange()
    },
    handleChange(val, old){
      this.getAllPrice()
    },
    getCheckData(){
      let arr = [],
        list2 = this.list2,
        curItem = this.curItem
      arr.push({
        dictCode: curItem.dictCode,
        parentDictCode: curItem.parentDictCode,
        dictName: curItem.dictName,
        buyNum: curItem.buyNum || 1
      })
      list2.forEach(item => {
        if(item.checked){
          arr.push({
            dictCode: item.dictCode,
            parentDictCode: item.parentDictCode,
            dictName: item.dictName,
            buyNum: item.buyNum || 1
          })
        }
      })
      return arr
    },
    getDictData(){
      let _this = this
      request.psfinddict({
        bodyId: utils.getBodyId()
      }).then(res => {
        if(res.code == 200){
          let arr = res.data.diceVo || []
          arr = arr.map(cur => {
            return {
              ...cur,
              checked: false,
              price: cur.price || 0,
              buyNum: 1,
              give1: cur.give1 || 0,
              give2: cur.give2 || 0,
              give3: cur.give3 || 0,
            }
          })
          _this.initDiceVo = [...res.data.diceVo]
          _this.setLists()
          _this.setFee()
          _this.selectObj.baseNames = res.data?.address || []
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    setFee(){
      let data = [...this.initDiceVo]
      if(data & data.length){
        data.forEach(cur => {
          if(cur.dictCode == '203001'){
            this.free1 = cur.price || 0
          }else if(cur.dictCode == '203002'){
            this.free2 = cur.price || 0
          }
        })
      }
    },
    setLists(){
      let arr1 = [], 
        arr2 = [], 
        arr3 = [], 
        curItem = {},
        data = [...this.initDiceVo]
      if(data.length){
        data.forEach(cur => {
          if(cur.parentDictCode == '201'){
            let obj = {
              ...cur
            }
            arr1.push(obj)
            arr3.push({dictName: cur.dictName, dictCode: cur.dictCode})
          }else if(cur.parentDictCode == '202'){
            arr2.push({
              ...cur
            })
          }
        })
      }
      this.list1 = arr1
      this.list2 = arr2
      this.selectObj.pro = arr3
    },
    selecChange2(val){
      let list = this.list1,
        curItem1 = {}
      list.forEach(cur => {
        if(cur.dictCode == val) {
          Object.assign(curItem1, cur)
        }
      })
      this.curItem = curItem1

      this.getAllPrice()
    },
    onCheckChange(e){
      console.log(e)
      this.getAllPrice()
    },
    resetList(){
      let list1 = this.list1 || [],
        list2 = this.list2 || []
      list1 = list1.map((item, index1) => {
        if(this.editType == 'add' && item.dictCode == '201001'){
          this.curItem = {
            ...item,
            price: item.price || 0,
            buyNum: 1
          }
        }
        return {
          ...item,
          checked: false,
          price: item.price || 0,
          buyNum: 1
        }
      })
      list2 = list2.map((item, index1) => {
        return {
          ...item,
          checked: false,
          price: item.price || 0,
          buyNum: 1
        }
      })
      this.list1 = list1
      this.list2 = list2
      if(this.editType == 'add'){
        this.getAllPrice()
      }
    },
    setItems(){
      let data = this.ruleForm?.labelItemDescVos || []
      if(data && data.length){
        let curItem = {},
        list1 = this.list1 || [],
        list2 = this.list2 || []
        // sels = []
        data.forEach((cur, index) => {
          if(!index){
            list1.forEach((item, index1) => {
              if(cur.dictCode == item.dictCode){
                curItem = {
                  ...item,
                  buyNum: cur.amount || 1
                }
                list1[index1].price = cur.unitPrice || 0
              }
            })
          }else{
            list2.forEach((item, index1) => {
              if(cur.dictCode == item.dictCode){
                item.buyNum = cur.amount || 1
                item.checked = true
                list2[index1].price = cur.unitPrice || 0
              }
            })
          }
        })
        this.curItem = curItem
        this.list1 = list1
        this.list2 = list2
        this.addressChange()
      }
    },
    addressChange(){
      let addr = this.ruleForm.address
      if(addr.indexOf('香港') > -1 || addr.indexOf('澳门') > -1 || addr.indexOf('台湾') > -1){
        this.ruleForm.expressFee = this.free2
      }else{
        this.ruleForm.expressFee = this.free1
      }
      this.getAllPrice()
    },
    getAllPrice(){
      let curItem = this.curItem,
        list = this.list2 || [],
        allNum = 0,
        allPrice = this.ruleForm.expressFee || 0
      allPrice += (curItem.price || 0) * (curItem.buyNum || 1)
      if(curItem.buyNum >= 2000){
        allNum += ((curItem.give3 / 100) * (curItem.buyNum || 1))
      }else if(curItem.buyNum >= 500){
        allNum += ((curItem.give2 / 100) * (curItem.buyNum || 1))
      }else if(curItem.buyNum >= 100){
        allNum += ((curItem.give1 / 100) * (curItem.buyNum || 1))
      }
      list.forEach(cur => {
        if(cur.checked){
          allPrice += (cur.price || 0) * (cur.buyNum || 1)
          if(cur.buyNum >= 2000){
            allNum += ((cur.give3 / 100) * (cur.buyNum || 1))
          }else if(cur.buyNum >= 500){
            allNum += ((cur.give2 / 100) * (cur.buyNum || 1))
          }else if(cur.buyNum >= 100){
            allNum += ((cur.give1 / 100) * (cur.buyNum || 1))
          }
        }
      })
      this.ruleForm.allPrice = allPrice
      this.ruleForm.freeNum = allNum
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = {...ruleForm, historyName: '历史地址'}
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
      this.resetList()
    },
    initEdit(){
      this.resetList()
      this.setItems()
    },
    initLook(){
      this.initEdit()
    },
    submitForm(formName) {
      let _this = this,
        editType = this.editType;
      let mesInfo = {
        add: "新增成功！",
        edit: "修改成功！"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'psspurchase' : 'psupdatelaberorder',
            target = {
            ..._this.ruleForm,
            itemVos: _this.getCheckData(),
            labelItemDescVos: ''
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.$refs.pay.initData(res.data.electronicLabelOrderId, res.data.orderMoney, res.data.electronicLabelOrderCode || _this.ruleForm.electronicLabelOrderCode)
              // _this.closeModel()
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false)
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields()
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .my-free{
    display: flex;
    vertical-align: middle;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #dcdfe6;
  }
  .my-free-img{
    width: 100px;
    height: 110px;
  }
  .my-free-check ::v-deep .el-checkbox__inner{
    width: 20px;
    height: 20px;
  }
  .my-free-check ::v-deep .el-checkbox__inner::after{
    height: 9px;
    left: 7px;
    top: 2px;
    width: 4px;
  }
  .my-free-select{
    width: 600px;
  }
  .my-free-cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fisp-price {
    color: rgb(236, 87, 41);
    font-size: 16px;
  }
  .free-certificate-shot{
    padding: 20px 10px;
    border-bottom: 1px solid #dcdfe6;
  }
</style>


