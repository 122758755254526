<template>
  <el-dialog 
    class="dialog-pay" 
    title="微信支付" 
    width="500px"
    :visible.sync="closeFlag" 
    center
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="paySucess">
      <div class="content">
        <p class="tip">
          支付金额
          <span class="orange" >￥</span>
          <span class="large orange">{{totalMoney}}</span>
        </p>
        <div class="code">
          <div class="imgStyle">
            <div id="qrcode" ref="qrCodeDiv"></div>
            <p class="text">
              <span class="green">微信</span>扫一扫支付
            </p>
          </div>
        </div>
        <div class="text-center">
          <el-button type="primary" size="middle" @click="closeModel">已成功支付</el-button>
        </div>
      </div>
  </el-dialog>
</template>

<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import QRCode from 'qrcodejs2';
export default {
  name: 'pay',
  props: {
  },
  data() {
    return {
      zfUrl: '',
      qrcode: "",
      closeFlag: false,
      timer: null,
      isPay: false,
      totalMoney: 0,
      payId: '',
      payCode: ''
    };
  },
  mounted() {
  },
  methods: {
    initData(id, money = 0, code){
      this.closeFlag = true
      this.getInfo(id)
      this.payId = id
      this.totalMoney = money
      this.payCode = code
    },
    paySucess(){
      let _this = this
      request.findpayment({
        electronicLabelOrderCode: this.payCode
      }).then(res => {
        if(res.code == 200){
          let result = res.data,
            obj = {
              'SUCCESS': '支付成功',
              'REFUND': '转入退款',
              'NOTPAY': '未支付',
              'CLOSED': '已关闭',
              'REVOKED': '已撤销',
              'USERPAYING': '用户支付中',
              'PAYERROR': '支付失败',
            }
          if(result){
            if(result == 'SUCCESS'){
              _this.updateStatus()
              _this.message(obj[result] || '获取支付数据有误，请关闭重试', "success")
            }else{
              _this.message(obj[result] || '获取支付数据有误，请关闭重试', "error")
              _this.closeModel()
            }
          }else{
            _this.message('获取支付数据有误，请关闭重试', "error")
          }
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    updateStatus(){
      let _this = this
      request.paysuccess(this.payId).then(res => {
        if(res.code == 200){
          _this.closeModel()
        }else{
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getInfo(id){
      let _this = this
      request.prepayment({
        electronicLabelOrderId: id
      }).then(res => {
        if(res.code == 200){
          let result = res.data
          if(result){
            _this.getCode(result)
          }else{
            _this.message('获取支付数据有误，请关闭重试', "error")
          }
        } else {
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        console.log(e)
      })
    },
    closeModel() {
      this.closeFlag = false
      clearInterval(this.timer)
      this.qrcode.innerHTML = ""
      this.$emit('getpay', this.isPay)
    },
    //绘制二维码
    getCode(url) {
      setTimeout(() => {
        this.qrcode = document.getElementById("qrcode")
        new QRCode(this.$refs.qrCodeDiv, {
            width: 200,  // 宽
            height: 200, // 高
            text: url, // 二维码内容,也可以直接放接口获取的数据
            // render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f'   // 自定义颜色默认黑白
            // foreground: '#ff0'
        })
      }, 0);

    },
    closeMaskzhifu() {
      this.codeShow = false;
      this.qrcode.innerHTML = "";
      clearInterval(this.timer);
    },
  },
  //销毁定时器
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer)
    }
    if(this.downTimer) {
      clearTimeout(this.downTimer)
    }
  }
};
</script>
<style lang="less" scoped>
  ::v-deep .el-dialog__header{
    background: #008000 !important;
  }
  .orange{
    color: orange;
  }
  .green{
    color: green;
  }
  .code{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text{
    font-size: 18px;
    text-align: center;
    padding: 15px;
    letter-spacing: 2px;
  }
  .large{
    font-size: 24px;
  }
  .tip{
    text-align: center;
    font-size: 16px;
    padding: 10px;
  }
  .text-center{
    text-align: center;
    padding: 20px;
  }
</style>

