<template>
  <el-dialog 
    class="my-dialog" 
    title="物流信息" 
    width="900px"
    :visible.sync="closeFlag" 
    center
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="closeModel">
      <div class="content" v-loading="loading" element-loading-text="查询中">
        <el-timeline :reverse="true">
          <el-timeline-item
            v-for="(activity, index) in progressInfo"
            :key="index"
            :class="progressInfo.length == (index + 1) ? 'green' : ''"
            :color="progressInfo.length == (index + 1) ? 'green' : ''"
            :timestamp="activity.acceptTime">
            {{activity.acceptStation}}
          </el-timeline-item>
        </el-timeline>
      </div>
      <div v-if="isNoData" class="no-data">暂无物流信息</div>
      <div class="forms-btns" slot="footer">
      <el-button @click="closeModel" size="small"> 关闭 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: 'steam',
  props: {
  },
  data() {
    return {
      closeFlag: false,
      loading: false,
      isNoData: false,
      progressInfo: [],
      // progressInfo: [
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 09:39:00",
      //       "ftime": "2018-05-10 09:39:00",
      //       "context": "已签收,感谢使用顺丰,期待再次为您服务",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 08:23:00",
      //       "ftime": "2018-05-10 08:23:00",
      //       "context": "[北京市]北京海淀育新小区营业点派件员 顺丰速运 95338正在为您派件",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 07:32:00",
      //       "ftime": "2018-05-10 07:32:00",
      //       "context": "快件到达 [北京海淀育新小区营业点]",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-10 02:03:00",
      //       "ftime": "2018-05-10 02:03:00",
      //       "context": "快件在[北京顺义集散中心]已装车,准备发往 [北京海淀育新小区营业点]",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-09 23:05:00",
      //       "ftime": "2018-05-09 23:05:00",
      //       "context": "快件到达 [北京顺义集散中心]",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-09 21:21:00",
      //       "ftime": "2018-05-09 21:21:00",
      //       "context": "快件在[北京宝胜营业点]已装车,准备发往 [北京顺义集散中心]",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-09 13:07:00",
      //       "ftime": "2018-05-09 13:07:00",
      //       "context": "顺丰速运 已收取快件",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-09 12:25:03",
      //       "ftime": "2018-05-09 12:25:03",
      //       "context": "卖家发货",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-09 12:22:24",
      //       "ftime": "2018-05-09 12:22:24",
      //       "context": "您的订单将由HLA（北京海淀区清河中街店）门店安排发货。",
      //       "location": ""
      //     },
      //     {
      //       "time": "2018-05-08 21:36:04",
      //       "ftime": "2018-05-08 21:36:04",
      //       "context": "商品已经下单",
      //       "location": ""
      //     }
      //   ],
    };
  },
  mounted() {
  },
  methods: {
    getautoComNum(data){
      let _this = this
      _this.progressInfo = []
      request.logisticsfind({
        logisticCompany: data.logisticCompany || '',
        orderNum: data.orderNum || '',
        payTime: data.payTime || '',
      }).then(res => {
        _this.loading = false
        if(res.code == 200){
          let result = res.data
          _this.progressInfo = result
        } else {
          _this.isNoData = true
          _this.message(res.msg || '', "error")
        }
      }).catch(e => {
        _this.isNoData = true
        _this.loading = false
        console.log(e)
      })
    },
    initData(data){
      this.isNoData = false
      this.loading = true
      this.getautoComNum(data)
      this.closeFlag = true
    },
    closeModel() {
      this.closeFlag = false
    }
  }
};
</script>
<style scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .green ::v-deep .el-timeline-item__content,
  .green ::v-deep .el-timeline-item__timestamp{
    color: green
  }
</style>

